import { theme as baseTheme, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

import Color from "color";

import { ColorMode, Colors } from "../../graphql/generated/apolloHooks";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Chip,
  Drawer,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Radio,
  Select,
  Tag,
  Text,
} from "./components";

// Use the this type to get theme typings while extending it
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

type ThemeOptions = {
  colors: Omit<Colors, "__typename">;
};

const IBM_PLEX_FONT = "'IBM Plex Sans', sans-serif";

const DEFAULT_COLORS: ThemeOptions["colors"] = {
  mode: ColorMode.Light,
  primary: "hsl(201, 100%, 30%)",
  gray: "hsl(201, 100%, 30%)",
  text: "hsl(0, 0%, 0%)",
  backgroundPrimary: "hsl(0, 0%, 100%)",
  backgroundSecondary: "hsl(201, 16%, 94%)",
};

const toColor = (color: string | null | undefined): Color => Color(color ?? undefined).hsl();

export const createTheme = ({ colors }: ThemeOptions): ReturnType<typeof extendTheme> => {
  const primaryColor = toColor(colors.primary ?? DEFAULT_COLORS.primary);
  const grayColor = toColor(colors.gray ?? DEFAULT_COLORS.gray);
  const textColor = toColor(colors.text ?? DEFAULT_COLORS.text);

  const backgroundPrimaryColor = colors.backgroundPrimary ?? DEFAULT_COLORS.backgroundPrimary;
  const backgroundSecondaryColor = colors.backgroundSecondary ?? DEFAULT_COLORS.backgroundSecondary;

  return extendTheme(
    {
      config: {
        initialColorMode: colors.mode,
      },
      fonts: {
        heading: IBM_PLEX_FONT,
        body: IBM_PLEX_FONT,
      },
      fontSizes: {
        "3xl": "2rem",
      },
      colors: {
        primary: {
          50: primaryColor.fade(0.93).string(),
          100: primaryColor.fade(0.9).string(),
          200: primaryColor.fade(0.85).string(),
          500: primaryColor.string(),
          600: primaryColor.darken(0.25).string(),
        },
        text: {
          500: textColor.fade(0.5).string(),
          600: textColor.fade(0.4).string(),
          700: textColor.fade(0.3).string(),
          900: textColor.fade(0.1).string(),
        },
        gray: {
          50: grayColor.saturationl(45).lightness(98).string(),
          100: grayColor.saturationl(38).lightness(95).string(),
          200: grayColor.saturationl(32).lightness(91).string(),
          300: grayColor.saturationl(25).lightness(84).string(),
          400: grayColor.saturationl(20).lightness(69).string(),
          500: grayColor.saturationl(15).lightness(52).string(),
          600: grayColor.saturationl(17).lightness(35).string(),
          700: grayColor.saturationl(23).lightness(23).string(),
          800: grayColor.saturationl(26).lightness(14).string(),
          900: grayColor.saturationl(21).lightness(11).string(),
        },
        background: {
          primary: backgroundPrimaryColor,
          secondary: backgroundSecondaryColor,
        },
      },
      shadows: {
        baseInverted: `hsla(0, 0%, 0%, 0.1) 0px -1px 3px 0px, ${baseTheme.colors.blackAlpha[100]} 0px -1px 2px 0px`,
        outline: `0 0 0 3px ${primaryColor.fade(0.7).string()}`,
      },
      components: {
        Badge,
        Button,
        Card,
        Checkbox,
        Chip,
        Drawer,
        Heading,
        Input,
        Link,
        Menu,
        Modal,
        Radio,
        Select,
        Tag,
        Text,
      },
    } as RecursivePartial<typeof baseTheme>,
    withDefaultColorScheme({ colorScheme: "primary" })
  );
};
