import { useContext } from "react";

import { AnalyticsContext } from "./context";
import { useTrackers } from "./trackers";

export { AnalyticsProvider } from "./context";

export const useAnalytics = () => {
  const integrations = useContext(AnalyticsContext);
  const trackers = useTrackers();

  return {
    ...trackers,
    loaded: Object.values(integrations).some(Boolean),
  };
};
