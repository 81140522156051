import { ReactNode } from "react";

export const clientOnly = <T,>(fn: () => T) => {
  if (typeof window !== "undefined") {
    return fn();
  }
};

export const useIsClientSide = () => typeof window !== "undefined";

export const useClientWindow = () => {
  if (typeof window !== "undefined") {
    return window;
  }
};

export const OnlyClientSide = ({ children }: { children: ReactNode }) => {
  const isClient = useIsClientSide();
  return <>{isClient ? children : null}</>;
};
