import { ChakraTheme, ThemeComponentProps, ThemeOverride } from "@chakra-ui/react";

type Override = NonNullable<ThemeOverride["components"]>;

const ROBOTO_FONT = "Roboto, sans-serif";

const color = (name: ThemeComponentProps<ChakraTheme>["colorScheme"], palette: number) =>
  [name, palette].join(".");

export const Badge: Override["Badge"] = {
  baseStyle: {
    fontFamily: ROBOTO_FONT,
  },
};

export const Button: Override["Button"] = {
  variants: {
    light: ({ colorScheme, colorMode }) => {
      const isLightMode = colorMode === "light";
      const bgPalette = isLightMode ? 100 : 200;
      const disabledPalette = isLightMode ? 50 : 100;

      return {
        textColor: color(colorScheme, 500),
        bgColor: color(colorScheme, bgPalette),
        _hover: {
          bgColor: color(colorScheme, disabledPalette),
          _disabled: { bgColor: color(colorScheme, bgPalette) },
        },
        _active: { bgColor: color(colorScheme, disabledPalette) },
      };
    },
    solid: ({ colorScheme }) => ({
      color: "white",
      bgColor: color(colorScheme, 500),
      _hover: {
        bgColor: color(colorScheme, 600),
        _disabled: { bgColor: color(colorScheme, 500) },
      },
      _active: { bgColor: color(colorScheme, 600) },
    }),
    link: ({ colorScheme }) => ({
      color: color(colorScheme, 500),
      fontFamily: "inherit",
      fontWeight: "normal",
      textDecoration: "none",
      lineHeight: "base",
      _hover: { textDecoration: "none", color: color(colorScheme, 600) },
      _active: { color: color(colorScheme, 600) },
    }),
    outline: ({ colorScheme }) => ({
      color: color(colorScheme, 800),
      borderColor: color(colorScheme, 200),
      _hover: { bgColor: color(colorScheme, 100) },
      _active: { bgColor: color(colorScheme, 200) },
    }),
  },
  sizes: {
    lg: {
      py: 4,
      px: 5,
      h: 14,
      w: { base: "full", md: 60 },
      fontSize: "md",
    },
  },
  baseStyle: {
    fontFamily: ROBOTO_FONT,
    fontWeight: "medium",
    borderRadius: "md",
  },
};

export const Card: Override["Box"] = {
  baseStyle: {
    paddingX: 6,
    paddingY: 6,
    position: "relative",
    backgroundColor: "background.primary",
    borderRadius: "base",
    boxShadow: "base",
  },
};

export const Checkbox: Override["Checkbox"] = {
  baseStyle: ({ colorMode }) => ({
    icon: {
      color: colorMode === "light" ? undefined : "text.900",
    },
  }),
};

export const Chip: Override["Chip"] = {
  baseStyle: ({ colorMode }) => ({
    fontFamily: ROBOTO_FONT,
    py: 2,
    px: 5,
    fontWeight: "normal",
    borderRadius: "full",
    color: "text.900",
    bgColor: colorMode === "light" ? "blackAlpha.100" : "whiteAlpha.100",
  }),
};

export const Drawer: Override["Drawer"] = {
  baseStyle: {
    dialog: {
      bgColor: "background.primary",
    },
  },
};

export const Heading: Override["Heading"] = {
  baseStyle: {
    color: "text.900",
  },
  variants: {
    colored: ({ colorScheme }) => ({
      color: color(colorScheme, 500),
      fontSize: "2xl",
      fontWeight: "medium",
      maxWidth: "full",
    }),
  },
};

export const Input: Override["Input"] = {
  variants: {
    outline: {
      field: {
        fontFamily: ROBOTO_FONT,
        fontSize: "md",
        lineHeight: "normal",
        height: 14,
        backgroundColor: "background.primary",
        color: "text.700",
        borderRadius: "base",
        fontWeight: "normal",
        _placeholder: {
          fontWeight: "normal",
          color: "text.600",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
    focusBorderColor: "primary.500",
  },
};

export const Link: Override["Link"] = {
  baseStyle: ({ colorScheme }) => ({
    color: color(colorScheme, 500),
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationThickness: "0.063rem",
    _hover: { color: color(colorScheme, 600) },
    _active: { color: color(colorScheme, 600) },
  }),
  sizes: {
    sm: {
      fontSize: "sm",
    },
    md: {
      fontSize: "md",
    },
    lg: {
      fontSize: "lg",
    },
  },
};

export const Menu: Override["Menu"] = {
  baseStyle: {
    list: {
      bgColor: "background.primary",
    },
  },
};

export const Modal: Override["Modal"] = {
  baseStyle: {
    dialog: {
      bgColor: "background.primary",
    },
  },
};

export const Radio: Override["Radio"] = {
  baseStyle: {
    control: {
      _checked: {
        bg: "primary.500",
        borderColor: "primary.500",
        color: "background.primary",
      },
    },
  },
};

export const Select: Override["Select"] = {
  variants: {
    outline: {
      field: {
        fontFamily: ROBOTO_FONT,
        fontSize: "md",
        lineHeight: "normal",
        height: 14,
        backgroundColor: "background.primary",
        color: "text.700",
        borderRadius: "base",
        "> option": {
          bgColor: "background.primary",
        },
        "> option:disabled": {
          color: "text.500",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
    focusBorderColor: "primary.500",
  },
};

export const Tag: Override["Tag"] = {
  baseStyle: {
    fontFamily: ROBOTO_FONT,
  },
};

export const Text: Override["Text"] = {
  baseStyle: {
    color: "text.900",
    lineHeight: "short",
  },
  variants: {
    roboto: {
      fontFamily: ROBOTO_FONT,
    },
  },
};
