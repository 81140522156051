import { PropsWithChildren, createContext, useEffect, useState } from "react";

import { useClientWindow } from "../client-side";
import { AnalyticsScripts } from "./scripts";
import { Analytics } from "./types";

export const AnalyticsContext = createContext<Analytics.Integrations>({});

export const AnalyticsProvider = ({
  children,
  ...props
}: PropsWithChildren<Analytics.ProviderProps>) => {
  const [integrations, loadIntegrations] = useState<Analytics.Integrations>({});
  const { gtag, fbq } = useClientWindow() ?? {};

  useEffect(() => {
    loadIntegrations({ facebookPixel: fbq, googleAnalytics: gtag });
  }, [fbq, gtag]);

  return (
    <AnalyticsContext.Provider value={integrations}>
      <AnalyticsScripts {...props} />
      {children}
    </AnalyticsContext.Provider>
  );
};
