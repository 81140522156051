import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  defaultDataIdFromObject,
  from,
} from "@apollo/client";
import { GraphQLErrors, NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { omit } from "remeda";

import generatedIntrospection from "./generated/fragments.json";

const commonOptions = {
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    dataIdFromObject(obj) {
      switch (obj.__typename) {
        case "Cart":
          return obj.id!.toString().replace(/-/g, "");
        default:
          return defaultDataIdFromObject(obj);
      }
    },
    typePolicies: {
      Query: {},
      Cart: {
        keyFields: ["id"],
        fields: {
          items: {
            merge: (_existing, incoming) => incoming,
          },
          possibleInstallments: {
            merge: (_existing, incoming) => incoming,
          },
        },
      },
      PatientItem: {
        fields: {
          possiblePatients: {
            merge: (_existing, incoming) => incoming,
          },
        },
      },
    },
  }),
} as ApolloClientOptions<NormalizedCacheObject>;

type ApiClientOptions = {
  url: string;
  shopId: string;
  getUserId?: () => string | undefined;
  getAuthToken?: () => string | undefined;
  onForbidden: (errors: GraphQLErrors) => void;
  onNetworkError: (error: NetworkError) => void;
};

export const createApolloClient = ({
  url,
  shopId,
  getUserId,
  getAuthToken,
  onForbidden,
  onNetworkError,
}: ApiClientOptions) => {
  const authAndUserIdLink = new ApolloLink((operation, forward) => {
    const token = getAuthToken ? getAuthToken() : null;
    const userId = getUserId ? getUserId() : null;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
        "x-netvacinas-user-id": userId || null,
      },
    }));
    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: ({ operationName }) => `${url}?op=${operationName}`,
    headers: {
      "x-netvacinas-shop-id": shopId,
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors.some(({ extensions }) => extensions?.code === "forbidden")) {
        onForbidden(graphQLErrors);
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: omit(oldHeaders, ["authorization"]),
        });
        return forward(operation);
      }
    }

    if (networkError) onNetworkError(networkError);
  });

  const client = new ApolloClient({
    ...commonOptions,
    link: from([authAndUserIdLink, errorLink, httpLink]),
  });

  return client;
};

export const apolloServerClient = new ApolloClient({
  ...commonOptions,
  link: createHttpLink({
    uri: ({ operationName }) =>
      `${
        process.env.SERVER_GRAPHQL_URL ?? process.env.NEXT_PUBLIC_GRAPHQL_URL
      }?op=${operationName}`,
  }),
});
