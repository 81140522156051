import { useMemo } from "react";
import { useContext } from "react";

import { groupBy, prop, values } from "remeda";
import { identity, pick } from "remeda";

import { AnalyticsContext } from "../context";
import { Trackers } from "./types";
import { ProductData } from "./types";

const groupProducts = (products: ProductData[]) =>
  values(groupBy(products, prop("id"))).map(products => ({
    ...products[0],
    quantity: products.length,
  }));

export const useTrackers = (): Trackers => {
  const { googleAnalytics = identity, facebookPixel = identity } = useContext(AnalyticsContext);

  return useMemo(
    () => ({
      trackInitCheckout: ({ products, total }) => {
        facebookPixel("track", "InitiateCheckout", {
          contents: groupProducts(products).map(pick(["id", "quantity"])),
          content_category: "Business & Industrial > Medical",
          content_type: "product",
          num_items: products.length,
          value: total,
          currency: "BRL",
        });
        googleAnalytics("event", "begin_checkout", {
          items: groupProducts(products).map(({ id, name, price, quantity }) => ({
            item_id: id,
            item_name: name,
            price,
            quantity,
          })),
          value: total,
          currency: "BRL",
        });
      },
      trackProductAddedToCart: ({ id, name, price }) => {
        facebookPixel("track", "AddToCart", {
          content_ids: [id],
          content_name: name,
          content_type: "product",
          content_category: "Business & Industrial > Medical",
          value: price,
          currency: "BRL",
        });
        googleAnalytics("event", "add_to_cart", {
          items: [{ item_id: id, item_name: name, price, quantity: 1 }],
          value: price,
          currency: "BRL",
        });
      },
      trackPageView: () => {
        facebookPixel("track", "PageView");
        googleAnalytics("event", "page_view");
      },
      trackProductView: ({ id, name, price }) => {
        facebookPixel("track", "ViewContent", {
          content_ids: [id],
          content_name: name,
          content_type: "product",
          content_category: "Business & Industrial > Medical",
          value: price,
          currency: "BRL",
        });
        googleAnalytics("event", "view_item", {
          items: [{ item_id: id, item_name: name, price, quantity: 1 }],
          value: price,
          currency: "BRL",
        });
      },
      trackPayment: ({ id, products, total }) => {
        facebookPixel("track", "Purchase", {
          id,
          contents: groupProducts(products).map(pick(["id", "quantity"])),
          content_type: "product",
          num_items: products.length,
          value: total,
          currency: "BRL",
        });
        googleAnalytics("event", "purchase", {
          transaction_id: id,
          items: groupProducts(products).map(({ id, name, price, quantity }) => ({
            item_id: id,
            item_name: name,
            price,
            quantity,
          })),
          value: total,
          currency: "BRL",
        });
      },
      trackSchedule: ({ id, products }) => {
        facebookPixel("track", "Schedule", {
          id,
          contents: groupProducts(products).map(pick(["id", "quantity"])),
          content_type: "product",
        });
        googleAnalytics("event", "schedule", {
          id,
          event_category: "schedule",
          event_label: "schedule",
        });
      },
      trackSearch: ({ text, category, manufacturer }) => {
        facebookPixel("track", "Search", { search_string: text, category, manufacturer });
        googleAnalytics("event", "search", { search_term: text, category, manufacturer });
      },
    }),
    [googleAnalytics, facebookPixel]
  );
};
