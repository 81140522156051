import { useEffect } from "react";

import { useRouter } from "next/router";
import Script from "next/script";

import { useTrackers } from "./trackers";
import { Analytics } from "./types";

export const FacebookPixel = ({ id }: Analytics.ScriptProps) => (
  <Script
    id="fbq-init"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('consent', 'grant');
  fbq('init', '${id}');
  fbq('track', 'PageView');
`,
    }}
  />
);

export const GoogleAnalytics = ({ id }: Analytics.ScriptProps) => (
  <>
    <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
    <Script
      id="gtag-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
            gtag('js', new Date());
            gtag('config', '${id}', {
              page_path: window.location.pathname,
            });
          `,
      }}
    />
  </>
);

export const AnalyticsScripts = ({
  googleAnalyticsId,
  facebookPixelId,
}: Analytics.ProviderProps) => {
  const { trackPageView } = useTrackers();
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", trackPageView);
    return () => router.events.off("routeChangeComplete", trackPageView);
  }, [trackPageView, router.events]);

  return (
    <>
      {facebookPixelId && <FacebookPixel id={facebookPixelId} />}
      {googleAnalyticsId && <GoogleAnalytics id={googleAnalyticsId} />}
    </>
  );
};
