import React, { useEffect, useMemo, useRef } from "react";

import { ChakraProvider } from "@chakra-ui/react";

import { ApolloProvider } from "@apollo/client";
import htmlParse from "html-react-parser";
import { DefaultSeo } from "next-seo";
import App, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import * as yup from "yup";
import { pt } from "yup-locale-pt";

import { Layout, Page } from "../components";
import { apolloServerClient, createApolloClient } from "../graphql";
import { ColorMode } from "../graphql/generated/apolloHooks";
import {
  ChainAndClinicsDocument,
  ChainAndClinicsQuery,
} from "../graphql/generated/typedDocumentNodes";
import { AnalyticsProvider } from "../lib/analytics";
import { useCurrentSession } from "../models/auth";
import { createTheme } from "../models/theme";

yup.setLocale({ ...pt, mixed: { ...pt.mixed, default: "${path} não é válido(a)" } });

function MyApp({
  Component,
  pageProps,
  chain,
  host,
  router: { asPath },
}: AppProps & { chain: ChainAndClinicsQuery["chain"]; host: string }) {
  const getLayout = (Component as Page<{}, Layout>).getLayout ?? (page => page);
  const props = { ...pageProps, layout: { ...pageProps.layout, chain } };

  const theme = useMemo(
    () => createTheme({ colors: chain?.colors ?? { mode: ColorMode.Light } }),
    [chain]
  );

  const { session, logout } = useCurrentSession();
  const userId = useRef<string>(session?.user?.id ?? "");
  const authToken = useRef<string>(session?.token ?? "");

  useEffect(() => {
    if (session) {
      authToken.current = session.token;
      userId.current = session.user.id;
    }
  }, [session]);

  const graphqlClient = useMemo(() => {
    return createApolloClient({
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL!,
      shopId: host ? host.replace(/https?:\/\//, "").replace(/:\d+(?:(?=\/)|$)/, "") : "",
      getUserId: () => userId.current,
      getAuthToken: () => authToken.current,
      onNetworkError: () => {},
      onForbidden: () => {
        logout();
      },
    });
  }, [host, logout]);

  return (
    <ApolloProvider client={graphqlClient}>
      <ChakraProvider theme={theme}>
        <AnalyticsProvider
          facebookPixelId={chain?.facebookPixelId ?? undefined}
          googleAnalyticsId={chain?.googleAnalyticsId ?? undefined}
        >
          {chain && (
            <DefaultSeo
              title={chain.name}
              twitter={{ cardType: "summary" }}
              openGraph={{
                url: host + asPath + (asPath === "/" ? "" : "/"), // Add trailling slash to url
                locale: "pt_BR",
                images: [
                  {
                    url: chain.logo.url,
                    alt: chain.name,
                    type: "image/png",
                    width: 1200, // Recommended og:image width
                    height: 680, // Recommended og:image height
                  },
                ],
              }}
            />
          )}
          {getLayout(<Component {...props} />, props)}
          {chain && <style>{chain.customCss}</style>}
          {chain?.customHtmlInHead && (
            // Parses the html string (that can have multiple tags) to react elements
            // and inserts it into <head> according to Next.js best practices
            <Head>{htmlParse(chain.customHtmlInHead)}</Head>
          )}
        </AnalyticsProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const headers = appContext.ctx.req?.headers;
  if (!headers?.host) return { ...appProps, notFound: true };

  const {
    data: { chain },
  } = await apolloServerClient.query({
    query: ChainAndClinicsDocument,
    variables: {
      chain: headers.host,
    },
  });

  if (!chain) return { ...appProps, notFound: true };
  if (!chain.enabled) return { ...appProps, notFound: true };

  return { ...appProps, chain, host: `https://${headers.host}` };
};

export default MyApp;
