import { useCallback, useEffect, useMemo } from "react";

import { ApolloError, makeVar, useReactiveVar } from "@apollo/client";
import { useLocalStorage } from "react-use";

import {
  UserAuthenticationTokenFragment,
  useCurrentUserLazyQuery,
} from "../../graphql/generated/apolloHooks";

type User = {
  id: string;
  name: string;
};

type Session = {
  token: string;
  expirationDate: string;
  user: User;
};

const authKey = "@netvacinas-shop/authentication4";

const currentSessionVar = makeVar<Session | null>(null);
export const useCurrentSession = () => {
  const [rawValue, setRawValue] = useLocalStorage<string | null>(authKey, null, { raw: true });
  const value = useReactiveVar(currentSessionVar);

  const session: Session | null = useMemo(() => {
    if (value) return value;

    try {
      return rawValue ? JSON.parse(rawValue) : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [rawValue, value]);

  useEffect(() => {
    currentSessionVar(rawValue ? JSON.parse(rawValue) : null);
  }, [rawValue]);

  const setSession = useCallback(
    (session: UserAuthenticationTokenFragment) => {
      currentSessionVar(session);
      setRawValue(JSON.stringify(session));
    },
    [setRawValue]
  );

  const logout = useCallback(() => {
    setRawValue(null);
    currentSessionVar(null);
  }, [setRawValue]);

  return { session, setSession, logout };
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const useCurrentUser = () => {
  const { session, logout } = useCurrentSession();

  const [fetchCurrentUser, { data, loading, called }] = useCurrentUserLazyQuery();

  useEffect(() => {
    (async () => {
      if (session?.token) {
        // Small delay to ensure this request is sent with the authentication headers
        await delay(100);
        try {
          const { data } = await fetchCurrentUser();

          if (!data?.currentUser) logout();
        } catch (e) {
          if ((e as ApolloError).networkError) logout();
        }
      }
    })();
  }, [fetchCurrentUser, logout, session?.token]);

  return { user: data?.currentUser, loading: loading || !called };
};

export const useIsAuthenticated = () => {
  const { user, loading } = useCurrentUser();
  return { isAuthenticated: user != null, loading };
};

export const useLogout = () => {
  const [, setValue] = useLocalStorage<string | null>(authKey, null, { raw: true });
  return () => setValue(null);
};
